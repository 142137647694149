var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex flex-column px-6 pt-6 pb-0",staticStyle:{"overflowY":"auto","height":"100%","width":"100%"},attrs:{"fluid":""}},[_c('v-col',{staticClass:"mb-9",staticStyle:{"flex":"0"}},[_c('v-row',{staticClass:"mt-0 mb-6 text-h5 font-weight-bold textBlack--text"},[_vm._v(" "+_vm._s(_vm.$t('deconve.manageUsers'))+" ")]),_c('v-row',[_c('div',{staticClass:"d-flex mb-3 align-center",staticStyle:{"flex":"1"},attrs:{"dense":""}},[_c('input-text',{attrs:{"max-width":"440px","left-icon":"mdi-magnify","placeholder":_vm.$t('deconve.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])],1),_c('v-col',{staticClass:"rounded pa-6",staticStyle:{"border":"1px solid","overflowY":"auto"},style:({
      borderColor: _vm.$vuetify.theme.themes.light.border,
      backgroundColor: _vm.$vuetify.theme.themes.light.inputBackground,
    })},[(_vm.invites.length > 0)?[_c('v-row',{staticClass:"mb-3 justify-space-between align-center"},[_c('span',{staticClass:"subtitle-2 text-sm-subtitle-1 font-weight-medium neutralPrimary--text"},[_vm._v(" "+_vm._s(_vm.$t('deconve.setting.workspace.invites'))+" ")]),_c('rectangle-button',{attrs:{"color":"primary","icon":"mdi-plus","data-cy":"invite-user-button","disabled":!_vm.$can('create', 'com.deconve.user')},on:{"clicked":_vm.goToInviteUserToWorkspacePage}},[_vm._v(" "+_vm._s(_vm.$t('deconve.newUser'))+" ")])],1),_c('v-row',[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"flex":"1","border":"1px solid"},style:({borderColor: _vm.$vuetify.theme.themes.light.border}),attrs:{"headers":_vm.inviteHeaders,"search":_vm.search,"items":_vm.invites,"items-per-page":10,"data-cy":"apikey-data-table","data-dd-privacy":"mask"},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.created_at,'DD/MM/YYYY')))])]}},{key:"item.tags",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"width":"100%","justify-content":"center"}},[_c('div',{staticClass:"pa-2 d-flex flex-wrap",staticStyle:{"width":"fit-content"}},_vm._l((item.tags),function(tag){return _c('tag',{key:tag.id,staticClass:"pr-1 pb-1",attrs:{"small":"","tag-id":tag.id}})}),1)])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('square-button',{attrs:{"outlined":"","content-class":"ml-2","color":"border","data-cy":"invite-button-delete","icon-color":"warn","icon-name":"mdi-trash-can-outline","disabled":!_vm.$can('delete', 'com.deconve.user')},on:{"clicked":function($event){return _vm.deleteInvite(item.id)}}})]}}],null,true)})],1)]:_vm._e(),_c('v-row',{staticClass:"mb-3 justify-space-between align-center",class:_vm.invites.length > 0 && 'mt-7'},[_c('span',{staticClass:"subtitle-2 text-sm-subtitle-1 font-weight-medium neutralPrimary--text"},[_vm._v(" "+_vm._s(_vm.$t('deconve.users'))+" ")]),(_vm.invites.length === 0)?_c('rectangle-button',{attrs:{"color":"primary","icon":"mdi-plus","data-cy":"invite-user-button","disabled":!_vm.$can('create', 'com.deconve.user')},on:{"clicked":_vm.goToInviteUserToWorkspacePage}},[_vm._v(" "+_vm._s(_vm.$t('deconve.newUser'))+" ")]):_vm._e()],1),_c('v-row',[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"flex":"1","border":"1px solid"},style:({borderColor: _vm.$vuetify.theme.themes.light.border}),attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.workspaceUsers,"items-per-page":10,"loading":_vm.isLoading,"loading-text":_vm.$t('deconve.loading'),"data-cy":"member-data-table","data-dd-privacy":"mask"},scopedSlots:_vm._u([{key:"item.user.name",fn:function(ref){
    var item = ref.item;
return [(item.user.name)?_c('span',[_vm._v(" "+_vm._s(item.user.name))]):_c('i18n',{attrs:{"path":"deconve.userNotFound"}},[_c('b',[_vm._v(_vm._s(item.id))])]),(_vm.userId === item.user.id)?_c('span',{staticClass:"neutral--text caption ml-1"},[_vm._v(_vm._s(("- " + (_vm.$t('deconve.you')))))]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [(item.created_at)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.created_at,'DD/MM/YYYY')))]):_vm._e()]}},{key:"item.tags",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"width":"100%","justify-content":"center"}},[_c('div',{staticClass:"pa-2 d-flex flex-wrap",staticStyle:{"width":"fit-content"}},_vm._l((item.tags),function(tag){return _c('tag',{key:tag.id,staticClass:"pr-1 pb-1",attrs:{"tag-id":tag.id}})}),1)])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('square-button',{attrs:{"outlined":"","data-cy":"member-button-more","content-class":"ml-2","color":"border","icon-color":"neutral","icon-name":"mdi-dots-vertical"}},[_c('v-list',[(_vm.userId !== item.user.id)?_c('v-list-item',{attrs:{"data-cy":"member-button-edit","disabled":!_vm.$can('update', 'com.deconve.user')},on:{"click":function($event){return _vm.goToEditUserPage(item)}}},[_vm._v(" "+_vm._s(_vm.$t('deconve.edit'))+" ")]):_vm._e(),_c('v-list-item',{attrs:{"data-cy":"member-button-delete","disabled":!_vm.$can('delete', 'com.deconve.user')},on:{"click":function($event){return _vm.deleteMember(item.id, item.user.name)}}},[_vm._v(" "+_vm._s(_vm.$t('deconve.delete'))+" ")]),_c('v-list-item',{on:{"click":function($event){return _vm.seePermissions(item.scopes)}}},[_vm._v(" "+_vm._s(_vm.$t('deconve.seePermissions'))+" ")])],1)],1)]}}],null,true)})],1)],2),_c('invite-user-to-workspace-dialog',{ref:"inviteUserDialog"}),_c('confirmation-dialog',{ref:"confirmationDialog"}),_c('scopes-manager-dialog',{ref:"scopesDialog"}),_c('workspace-user-manager-dialog',{ref:"workspaceUserDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }