<template>
  <v-container
    class="d-flex flex-column px-6 pt-6 pb-0"
    style="overflowY: auto; height: 100%; width: 100%"
    fluid
  >
    <v-col
      class="mb-9"
      style="flex: 0"
    >
      <v-row class="mt-0 mb-6 text-h5 font-weight-bold textBlack--text">
        {{ $t('deconve.manageUsers') }}
      </v-row>
      <v-row>
        <div
          dense
          class="d-flex mb-3 align-center"
          style="flex: 1"
        >
          <input-text
            v-model="search"
            max-width="440px"
            left-icon="mdi-magnify"
            :placeholder="$t('deconve.search')"
          />
        </div>
      </v-row>
    </v-col>
    <v-col
      class="rounded pa-6"
      style="border: 1px solid; overflowY: auto"
      :style="{
        borderColor: $vuetify.theme.themes.light.border,
        backgroundColor: $vuetify.theme.themes.light.inputBackground,
      }"
    >
      <template v-if="invites.length > 0">
        <v-row class="mb-3 justify-space-between align-center">
          <span
            class="subtitle-2 text-sm-subtitle-1 font-weight-medium neutralPrimary--text"
          >
            {{ $t('deconve.setting.workspace.invites') }}
          </span>
          <rectangle-button
            color="primary"
            icon="mdi-plus"
            data-cy="invite-user-button"
            :disabled="!$can('create', 'com.deconve.user')"
            @clicked="goToInviteUserToWorkspacePage"
          >
            {{ $t('deconve.newUser') }}
          </rectangle-button>
        </v-row>
        <v-row>
          <v-data-table
            style="flex: 1; border: 1px solid"
            :style="{borderColor: $vuetify.theme.themes.light.border}"
            :headers="inviteHeaders"
            :search="search"
            :items="invites"
            :items-per-page="10"
            data-cy="apikey-data-table"
            data-dd-privacy="mask"
            class="elevation-0"
          >
            <template v-slot:[`item.created_at`]="{item}">
              <span>{{ item.created_at | moment('DD/MM/YYYY') }}</span>
            </template>
            <template v-slot:[`item.tags`]="{item}">
              <div
                class="d-flex"
                style="width: 100%; justify-content: center"
              >
                <div
                  class="pa-2 d-flex flex-wrap"
                  style="width: fit-content"
                >
                  <tag
                    v-for="tag in item.tags"
                    :key="tag.id"
                    class="pr-1 pb-1"
                    small
                    :tag-id="tag.id"
                  />
                </div>
              </div>
            </template>
            <template v-slot:[`item.actions`]="{item}">
              <square-button
                outlined
                content-class="ml-2"
                color="border"
                data-cy="invite-button-delete"
                icon-color="warn"
                icon-name="mdi-trash-can-outline"
                :disabled="!$can('delete', 'com.deconve.user')"
                @clicked="deleteInvite(item.id)"
              />
            </template>
          </v-data-table>
        </v-row>
      </template>
      <v-row
        class="mb-3 justify-space-between align-center"
        :class="invites.length > 0 && 'mt-7'"
      >
        <span
          class="subtitle-2 text-sm-subtitle-1 font-weight-medium neutralPrimary--text"
        >
          {{ $t('deconve.users') }}
        </span>
        <rectangle-button
          v-if="invites.length === 0"
          color="primary"
          icon="mdi-plus"
          data-cy="invite-user-button"
          :disabled="!$can('create', 'com.deconve.user')"
          @clicked="goToInviteUserToWorkspacePage"
        >
          {{ $t('deconve.newUser') }}
        </rectangle-button>
      </v-row>
      <v-row>
        <v-data-table
          style="flex: 1; border: 1px solid"
          :style="{borderColor: $vuetify.theme.themes.light.border}"
          :headers="headers"
          :search="search"
          :items="workspaceUsers"
          :items-per-page="10"
          :loading="isLoading"
          :loading-text="$t('deconve.loading')"
          data-cy="member-data-table"
          data-dd-privacy="mask"
          class="elevation-0"
        >
          <template v-slot:[`item.user.name`]="{item}">
            <span v-if="item.user.name"> {{ item.user.name }}</span>
            <i18n
              v-else
              path="deconve.userNotFound"
            >
              <b>{{ item.id }}</b>
            </i18n>
            <span
              v-if="userId === item.user.id"
              class="neutral--text caption ml-1"
            >{{ `- ${$t('deconve.you')}` }}</span>
          </template>
          <template v-slot:[`item.created_at`]="{item}">
            <span v-if="item.created_at">{{ item.created_at | moment('DD/MM/YYYY') }}</span>
          </template>
          <template v-slot:[`item.tags`]="{item}">
            <div
              class="d-flex"
              style="width: 100%; justify-content: center"
            >
              <div
                class="pa-2 d-flex flex-wrap"
                style="width: fit-content"
              >
                <tag
                  v-for="tag in item.tags"
                  :key="tag.id"
                  class="pr-1 pb-1"
                  :tag-id="tag.id"
                />
              </div>
            </div>
          </template>
          <template v-slot:[`item.actions`]="{item}">
            <square-button
              outlined
              data-cy="member-button-more"
              content-class="ml-2"
              color="border"
              icon-color="neutral"
              icon-name="mdi-dots-vertical"
            >
              <v-list>
                <v-list-item
                  v-if="userId !== item.user.id"
                  data-cy="member-button-edit"
                  :disabled="!$can('update', 'com.deconve.user')"
                  @click="goToEditUserPage(item)"
                >
                  {{ $t('deconve.edit') }}
                </v-list-item>
                <v-list-item
                  data-cy="member-button-delete"
                  :disabled="!$can('delete', 'com.deconve.user')"
                  @click="deleteMember(item.id, item.user.name)"
                >
                  {{ $t('deconve.delete') }}
                </v-list-item>
                <v-list-item
                  @click="seePermissions(item.scopes)"
                >
                  {{ $t('deconve.seePermissions') }}
                </v-list-item>
              </v-list>
            </square-button>
          </template>
        </v-data-table>
      </v-row>
    </v-col>
    <invite-user-to-workspace-dialog ref="inviteUserDialog" />
    <confirmation-dialog ref="confirmationDialog" />
    <scopes-manager-dialog ref="scopesDialog" />
    <workspace-user-manager-dialog ref="workspaceUserDialog" />
  </v-container>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import SquareButton from '@/components/SquareButton.vue';
import InputText from '@/components/InputText.vue';
import RectangleButton from '@/components/RectangleButton.vue';
import Tag from '@/components/Tag.vue';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import ScopesManagerDialog from '@/components/ScopesManagerDialog.vue';
import InviteUserToWorkspaceDialog from '@/components/InviteUserToWorkspaceDialog.vue';
import WorkspaceUserManagerDialog from '@/components/WorkspaceUserManagerDialog.vue';

export default {
  name: 'Members',
  components: {
    InputText,
    RectangleButton,
    ScopesManagerDialog,
    Tag,
    ConfirmationDialog,
    InviteUserToWorkspaceDialog,
    WorkspaceUserManagerDialog,
    SquareButton,
  },
  data() {
    return {
      headers: [
        { text: this.$t('deconve.name'), value: 'user.name' },
        { text: this.$t('deconve.email'), value: 'user.email' },
        { text: this.$t('deconve.createdAt'), value: 'created_at' },
        { text: this.$t('deconve.role'), value: 'role' },
        { text: this.$t('deconve.tags'), value: 'tags', align: 'center' },
        {
          text: this.$t('deconve.actions'), value: 'actions', align: 'end', sortable: false,
        },
      ],
      inviteHeaders: [
        { text: this.$t('deconve.email'), value: 'email' },
        { text: this.$t('deconve.sentBy'), value: 'sent_by.name' },
        { text: this.$t('deconve.createdAt'), value: 'created_at' },
        { text: this.$t('deconve.role'), value: 'role' },
        { text: this.$t('deconve.tags'), value: 'tags', align: 'center' },
        {
          text: this.$t('deconve.actions'), value: 'actions', align: 'end', sortable: false,
        },
      ],
      search: '',
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      workspaceUsers: 'workspace/workspaceUsers',
      invites: 'workspace/invites',
      userId: 'userId',
      userRole: 'userRole',
    }),
  },
  created() {
    this.getMembers();
    this.getInvites();
  },
  methods: {
    ...mapActions({
      getWorkspaceUsers: 'workspace/getWorkspaceUsers',
      getWorkspaceUserTags: 'workspace/getWorkspaceUserTags',
      getInvites: 'workspace/getInvites',
      deleteInvite: 'workspace/deleteInvite',
      deleteWorkspaceUser: 'workspace/deleteWorkspaceUser',
    }),
    deleteMember(id, name) {
      this.$refs.confirmationDialog.open(
        this.$t('deconve.setting.workspace.member.confirmDeletion.title'),
        this.$t('deconve.setting.workspace.member.confirmDeletion.message', { n: name }),
      ).then((response) => {
        if (response) {
          this.deleteWorkspaceUser(id)
            .then(() => this.getMembers())
            .catch(() => this.getMembers());
        }
      });
    },
    seePermissions(scopes) {
      this.$refs.scopesDialog.open({
        title: this.$t('deconve.userPermissions'),
        scopes,
        locked: true,
      });
    },
    getMembers() {
      this.isLoading = true;
      this.getWorkspaceUsers().then(() => {
        this.workspaceUsers.forEach((member) => {
          this.getWorkspaceUserTags(member.id);
        });
        this.isLoading = false;
      });
    },
    goToInviteUserToWorkspacePage() {
      this.$router.push({ name: 'settings-new-user' });
    },
    goToEditUserPage(member) {
      this.$router.push({
        name: 'settings-edit-user',
        params: { userId: member.id },
      });
    },
  },
};
</script>
